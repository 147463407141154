<template>
  <div id="page-user-view">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
      <shipblu-card class="mb-base p-5" :cardLoading="apiKeyLoading">
        <div class="flex justify-between items-center mb-4">
          <h4 class="font-normal">{{$t('Merchant API Keys')}}</h4>
          <div>
            <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
              {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
            </vs-alert>   
            <div v-else>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  class="px-5 py-3 btn active-btn rounded-lg"
                  @click="newAPIKey('post')"
                  :disabled="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant'"
                  >
                  <div class="flex items-center">
                    <span class="text-base">{{$t('Generate API Key')}}</span>
                  </div>
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <div class="lg:flex items-center justify-between" v-if="apiKey">
          <label class="font-semibold">{{$t('API Key')}}</label>
          <p class="break-words">{{apiKey}}</p>
          <div v-if="merchantStatus === 'active'" class="text-right lg:mt-0 mt-2">
            <vs-button class="mr-2 py-2 px-4" @click="reNewAPIKey()">{{$t('Renew')}}</vs-button>
            <vs-button class="py-2 px-4" color="danger" @click="deleteAPIKey()">{{$t('Delete')}}</vs-button>
          </div>
        </div>
      </shipblu-card>
      </form>
    </div>
  </div>
</template>

<script>
import common from '../../../assets/utils/common.js'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  props: ['merchantStatus'],
  data () {
    return {
      merchantID: '',
      apiKey: '',
      apiKeyLoading: false,
      merchant: {}
    }
  },
  methods: {
    newAPIKey () {
      this.apiKeyLoading = true
      sendRequest(true, false, this, 'api/v1/merchant-api-keys/', 'post', {}, true, 
        (response) => {
          this.apiKey = response.data.API_Key
          this.apiKeyLoading = false
          const user = JSON.parse(localStorage.getItem('merchant'))
          localStorage.removeItem('merchant')
          user.api_key = response.data.API_Key
          localStorage.setItem('merchant', JSON.stringify(user))
          this.$emit('APIKey', this.apiKey)
        }, (error) => {
          common.notifyResponseError(this, error)
          this.apiKeyLoading = false
        }
      )
    },
    reNewAPIKey () {
      this.apiKeyLoading = true
      sendRequest(true, false, this, 'api/v1/merchant-api-keys/', 'patch', {}, true,
        (response) => {
          this.apiKey = response.data.API_Key
          this.apiKeyLoading = false
          const user = JSON.parse(localStorage.getItem('merchant'))
          localStorage.removeItem('merchant')
          user.api_key = response.data.API_Key
          localStorage.setItem('merchant', JSON.stringify(user))
          this.$emit('APIKey', this.apiKey)
        }
      )
    },
    deleteAPIKey () {
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, 'api/v1/merchant-api-keys/', 'delete', null, true, 
        (response) => {
          this.apiKey = response.data.API_Key
          const user = JSON.parse(localStorage.getItem('merchant'))
          user.api_key = ''
          this.$emit('APIKey', '')
          localStorage.setItem('merchant', JSON.stringify(user))
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.apiKey = this.merchant.api_key
      this.$emit('APIKey', this.apiKey)
    })
  }
}
</script>