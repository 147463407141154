<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Webhook Events')}}</h2>
      </div>
    </div>
    
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between main mb-4">
        <div class="flex flex-wrap items-center data-list-btn-container">
          <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
            v-for="status in statusFilters" :key="status.index" @click="changeFilter(status.value)"
            v-bind:style="!$route.query.filter && status.value === 'all' ? `background:${status.color ? status.color : '#1C5BFE'}; color: white` : $route.query.filter && $route.query.filter === status.value ? `${getstyle(status.value)}; color: white;` : 'color: #6C84A3;'">
            <div class="text-sm capitalize">{{ $t(status.name) }}</div>
            <div v-if="status.value !== 'all'" class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="getstyle(status.value)"></div>
          </div>
        </div>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      search
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="events"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Payload')}}</shipblu-th>
        <shipblu-th>{{$t('Response')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].payload">
            {{ data[indextr].payload }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].subscribed_to">
            {{ data[indextr].response }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../../../http/axios/requestHelper.js'
import common from '../../../../assets/utils/common'
import ShipbluTable from '../../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      events: [],
      offset: 0,
      totalRows: 0,
      searchVal: '',
      searchedValue: ' ',
      searchInProgress: false,
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'queued',
          value: 'queued'
        },
        {
          name: 'success',
          value: 'success'
        },
        {
          name: 'failed',
          value: 'fail'
        }
      ],
      filters: '',
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          webhookID: this.$route.query.webhookID,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadWebhooks()
    }
  },
  methods: {
    getstyle (status) {
      return `background: ${common.getOrderStatusColor(status)}`
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    changeFilter (value) {
      this.filters = value
      if (value === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          webhookID: this.$route.query.webhookID,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadWebhooks()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadEventsSearch)
    },
    loadEventsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadWebhooks()
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadWebhooks () {
      this.tableLoader = true
      this.searchInProgress = true
      sendRequest(true, false, this, `api/v1/webhooks/${this.$route.query.webhookID}/events/?offset=${this.offset}&limit=${this.maximumItems}${this.searchVal ? `&search=${this.searchVal}` : ''}${this.filters !== '' ? `&status=${this.filters}` : '' }`, 'get', null, true,
        (response) => {
          this.events = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  created () {
    this.filters = this.$route.query.filter ? this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadWebhooks()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
