<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="webhookModal"
      :title="type === 'add' ? `${$t('Add Webhook')}`: `${$t('Edit Webhook')}`"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|url:require_protocol'" :label-placeholder="$t('Endpoint')" name="endpoint" v-model="data.endpoint" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('endpoint')">{{ errors.first('endpoint') }}</span>
          </div>
          <div class="vx-col w-1/2 "> 
            <v-select v-validate="'required'" class="mt-10 w-full" name="subscribed to" multiple v-model="data.subscribed_to" :placeholder="$t('Subscribed To')" label="name" :options="subscribe"/>
            <span class="text-danger text-sm" v-show="errors.has('subscribed to')">{{ errors.first('subscribed to') }}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full mt-4 mb-base">
            <vs-textarea :placeholder="$t('Headers (optional)')" name="endpoint" v-model="data.headers" class="w-full"/>
            <span class="text-sm">{{ ('The headers field should be in json format. For example: {"Authorization": "Basic key>"}') }}</span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="font-semibold btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="addWebhook" v-if="type === 'add'" class="font-semibold btn active-btn ">{{ $t('Add') }}</button>
          <button @click="updateWebhook" v-else class="font-semibold btn active-btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import i18nData from '../../../../i18n/i18nData.js'
import { sendRequest }  from '../../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['webhookModal', 'type', 'data'],
  data () {
    return {
      subscribe: [
        'out_for_pickup',
        'picked_up',
        'in_transit',
        'out_for_delivery',
        'delivery_attempted',
        'delivered',
        'return_to_origin',
        'out_for_return',
        'return_postponed',
        'return_attempted',
        'returned',
        'cancelled'
      ]
    }
  }, 
  watch: {
    data () {
      if (this.type === 'edit' && this.data.headers) {
        this.data.headers = JSON.stringify(this.data.headers)
      }
    }
  },
  methods: {
    updateWebhook () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const webhook = {
            endpoint: this.data.endpoint,
            subscribed_to: this.data.subscribed_to,
            headers: this.data.headers
          }
          if (!this.isOnline) this.$emit('webhookModal', false)
          sendRequest(false, false, this, `api/v1/webhooks/${this.data.id}/`, 'patch', webhook, true,
            () => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['Webhook'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('webhookModal', false)
              this.$emit('loadWebhooks')
            }
          )
        }
      })
    },
    addWebhook () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('webhookModal', false)
          sendRequest(false, false, this, 'api/v1/webhooks/', 'post', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Webhook'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$emit('webhookModal', false)
              this.$emit('loadWebhooks')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('webhookModal', false)
      this.$emit('loadWebhooks')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>
