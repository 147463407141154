<template>
  <div class="text-left">
    <div>
      <h3 class="mb-6">Integration Instructions</h3>
      <vs-list>
        <vs-list-header title="Install Plugin"></vs-list-header>
        <vs-list-item title="1. Download" subtitle="Click download plugin on the right">
          <vs-button target="blank" :href="releaseVersion === 'staging' ? 
            'https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/woocommerce-shipblu-services.zip' 
            : 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce-shipblu-services.zip'">
            Download Plugin
          </vs-button>
        </vs-list-item>
        <vs-list-item class="block" title="2. From your dashboard, click “Plugins”, and “Add New”">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-1.png">
        </vs-list-item>
        <vs-list-item class="block" title="3. Click “Upload Plugin”">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-2.png">
        </vs-list-item>
        <vs-list-item class="block" title="4. Click “Choose File” and select the plugin file you just downloaded.">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-3.png">
        </vs-list-item>
        <vs-list-item class="block" title="5. Click “Install Now”">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-4.png">
        </vs-list-item>
        <vs-list-item class="block" title="6. After installation, click “Activate Plugin”">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-5.png">
        </vs-list-item>
        <vs-list-item class="block" title="7. Now, go to Woocommerce tab and select “Settings”. Choose the Shipping tab, and under ShipBlu Integration, enter your API Key (find it in the top of this page) and click “Enable rates at checkout”. Then, click “Save changes”.">
          <img class="w-full" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/woocommerce/woo-commerce-instructions-6.png">
        </vs-list-item>
        <vs-list-item title="8. Congratulations!" subtitle="All orders placed on your store are automatically sent to your ShipBlu account. No action is required from your side to sync orders."></vs-list-item>
      </vs-list>
      <div>
        <p class="font-semibold">Facing difficulty doing this?</p>
        <p>Reach out to our team at <a href="mailto:tech@shipblu.com?subject=Help integrating WooCommerce">tech@shipblu.com</a> and we will get on a call with you to do it. It takes less than 5 minutes to handle this for you.</p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      text: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION
    }
  }
}
</script>
<style scoped>
</style>