<template>
  <div :class="testWebhookModal ? 'block' : 'hidden'">
    <div :class="testWebhookModal ? 'webhook-test-modal rounded-md' : ''">
      <div class="title py-4 flex justify-between">
        <p class="ml-6 text-primary text-base font-semibold">{{ $t('Webhook Test') }}</p>
        <feather-icon @click="closeModal" icon="XIcon" svgClasses="h-5 w-5" class="cursor-pointer mr-6"/>
      </div>
      <p class="py-4 px-6 text-base font-medium">{{test}}}</p>
    </div>
    <div :class="testWebhookModal ? 'show-webhook-test-modal' : ''"></div>
  </div>
</template>

<script>
export default {
  props: ['test', 'testWebhookModal'],
  methods: {
    closeModal () {
      this.$emit('testWebhookModal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.webhook-test-modal {
  width: 500px;
  position: fixed;
  top: calc(50% - 58.605px);
  right: calc(50% - 250px);
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.38);
  p {
    color: #6C84A3;
  }
}
.show-webhook-test-modal {
  visibility: visible;
  opacity: 1 !important;
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
}
@media (max-width: 767px) {
  .exchange-modal {
    width: unset;
    max-width: 300px;
    right: calc(50% - 150px);
  }
}
.title {
  background: rgba(220, 238, 255, 0.5);
  border-radius: 6px 0px 0px 6px;
  color: '#1C5BFE'
}
</style>