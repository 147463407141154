<template>
  <div>
    <h3 class="mb-6">Integration Instructions</h3>
    <vs-list>
      <vs-list-header title="Install Plugin"></vs-list-header>

      <!-- DOWNLOAD -->
      <div class="list-item sm:flex gap-2 justify-between items-center">
        <div>
          <p class="font-bold">Download</p>
          <p>Click download plugin on the right</p>
        </div>
        <div class="text-right sm:mt-0 mt-2">
          <vs-button target="blank" :href="magentoURL">Download Plugin</vs-button>
        </div>
      </div>

      <!-- REQUIREMENTS -->
      <div class="list-item">
        <p class="font-bold">Requirements</p>
        <li>Magento Version 2</li>
        <li>PHP >= 7.0</li>
      </div>

      <!-- INSTALLATION -->
      <div class="list-item">
        <p class="font-bold">Installation</p>
        <div>
          <p class="font-medium">1. You can purchase the plugin from magento store then jump to step 2. Or if you want to download the plugin from our portal then follow these steps:</p>
          <div class="px-4">
            <li class="mt-1"><code class="font-semibold">$ cd /path/to/any/folder/</code></li>
            <li class="mt-1"><code class="font-semibold">$ mkdir ShipBlu && cd ShipBlu</code></li>
            <li class="mt-1"><code class="font-semibold">$ mkdir Shipping && cd Shipping</code></li>
            <li class="mt-1">
              <span>UnZip The Folder Here </span>
              <code class="font-semibold">$ unzip magento.zip -d .</code>
            </li>
            <li class="mt-1">
              <span>Delete Zip File </span>
              <code class="font-semibold">$ rm magento.zip</code>
            </li>
            <li class="mt-1">
              <span>Open Composer Jons File </span>
              <code class="font-semibold">$ nano composer.json</code>
            </li>
            <li class="mt-1">
              <span>Add This key </span>
              <code class="font-semibold">
                ...
                "repositories": [
                    {
                      "type": "composer",
                      "url": "https://repo.magento.com/"
                    },
                    {
                      "type": "path",
                      "url": "/path/to/any/folder/*/*",
                      "options": {
                      "symlink": false
                    }
                  }
                ],
              </code>
            </li>
            <li class="mt-1">If All These are done you can go to step 2</li>
          </div>
        </div>
        <p class="mt-1">
          <span class="font-medium">2. Install the module </span>
          <code class="font-semibold">$ composer require shipblu/module-shipping</code>
        </p>
        <p class="mt-1">
          <span class="font-medium">3. Enable the module </span>
          <code class="font-semibold">$ bin/magento module:enable ShipBlu_Shipping</code>
        </p>
        <p class="font-medium">4. Content Security Policy For ShipBlu APIs</p>
        <div class="px-4">
          <li class="mt-1"><code class="font-semibold">$ rm -rf var/cache/*</code></li>
          <li class="mt-1"><code class="font-semibold">$ rm -rf var/page_cache/*</code></li>
          <li class="mt-1"><code class="font-semibold">$ rm -rf pub/static/*</code></li>
        </div>
        <p class="mt-1">
          <span class="font-medium">5. Upgrade Magento </span>
          <code class="font-semibold">$ bin/magento setup:upgrade</code>
        </p>
        <p class="mt-1">
          <span class="font-medium">6. Compile Magento </span>
          <code class="font-semibold">$ bin/magento setup:di:compile</code>
        </p>
        <p class="mt-1">
          <span class="font-medium">7. Deploy Static Files </span>
          <code class="font-semibold">$ bin/magento setup:static-content:deploy</code>
        </p>
        <p class="mt-1">
          <span class="font-medium">8. Clean Cache </span>
          <code class="font-semibold">$ bin/magento cache:clean</code>
        </p>
        <p class="mt-1">
          <span class="font-medium">9. Flush Cache </span>
          <code class="font-semibold">$ bin/magento cache:flush</code>
        </p>
      </div>

      <!-- CONFIGURATIONS -->
      <div class="list-item">
        <p class="font-bold">Configurations</p>
        <div>
          <p class="font-medium">After you are done installing magento you need to make some configurations like adding you <b>API Key</b>.</p>
          <div class="mt-1">
            <p class="font-semibold">1. Go to the store configurations</p>
            <img class="max-w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/Store-Configurations-Menu.png" alt="Store configurations page"/>
          </div>
          <div class="mt-4">
            <p class="font-semibold">2. Set the configurations scope to be default configurations.</p>
            <img class="max-w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/Store-Default-Configurations.png" alt="Set configurations scope to be default"/>
          </div>
          <div class="mt-4">
            <p class="font-semibold">3. Click on SHIPBLU > ShipBlu Integrations > ShipBlu Integration Options</p>
            <img class="max-w-full lg:w-1/2" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/Configurations.png" alt="Configurations"/>
          </div>
          <p class="font-semibold mt-4">4. Add your APIKey</p>
          <p class="font-semibold mt-2">5. You can Enable AutoSend Orders to ShipBlu in customer checkout or not (based in your preference)</p>
          <p class="font-semibold mt-2">6. If you have staging account you can make this (YES) which will send orders to your staging account instead of production on,
            but take note that the API Key for staging account is different from production one</p>
        </div>
      </div>

      <!-- FEATURES -->
      <div class="list-item">
        <p class="font-bold">Features</p>
        <div>
          <li>Orders sent to ShipBlu automatically at customer checkout order.</li>
          <li class="mt-1">ShipBlu Admin page with these capabilities:</li>
          <div class="px-6">
            <li>Filter orders (Unsent, sent) to shipblu.</li>
            <img class="max-w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/filter-orders.png" alt="Filter orders"/>
            <li class="mt-4">Search orders by tracking number.</li>
            <img class="max-w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/search-tracking-number.png" alt="Search orders by tracking number"/>
            <li>Select specific orders to send to ShipBlu.</li>
            <img class="max-w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/select-orders.png" alt="Select specific orders to send to ShipBlu"/>
            <li>AutoZone Orders before sending them to ShipBlu.</li>
            <img class="w-full" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/magento-images/auto-zone-orders.png" alt="AutoZone Orders"/>
          </div>
          <li>Configure The Extension</li>
          <div class="px-6">
            <li>Add APIKey to send orders to ShipBlu.</li>
            <li>Configure AutoSend orders to ShipBlu On Customer Checkout order or not.</li>
            <li>Send Orders To ShipBlu Staging Account (If you have one) for testing or send directlly to ShipBlu Production.</li>
          </div>
        </div>
      </div>
    </vs-list>
    <div>
      <p class="font-semibold">Facing difficulty doing this?</p>
      <p>Reach out to our team at <a href="malto:tech@shipblu.com?subject=Help Integrating Magento Plugin">tech@shipblu.com</a> and we will get on a call with you to do it. It takes less than 5 minutes to handle this for you.</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      magentoURL: process.env.VUE_APP_MAGENTO_PLUGIN
    }
  }
}
</script>

<style>
.list-item{
  border-bottom: 1px solid rgba(0,0,0,.08);
  padding: 5px;
}
</style>