<template>
  <div class="text-left">
    <div>
      <h3 class="mb-6">Integration Instructions</h3>
      <vs-list>
        <vs-list-header title="Install App"></vs-list-header>
        <vs-list-item title="1. From Shopify App Store" subtitle="Our app is publicly listed on Shopify App Store">
          <vs-button target="blank" href="https://apps.shopify.com/shipblu">Install from Shopify App Store</vs-button>
        </vs-list-item>
        <vs-list-item title="2. Settings Page" subtitle="After installing the app, go to the settings page (on the Shopify App) and add the API key. Your API key is shown at the top of this page."></vs-list-item>
        <vs-list-item title="3. Congratulations!" subtitle="All orders placed on your store are automatically sent to your ShipBlu account. No action is required from your side to sync orders."></vs-list-item>
      </vs-list>
    </div>
  </div>
</template>
<script>

export default {
  props: ['APIKey'],
  data () {
    return {
      text: ''
    }
  }
}
</script>
<style scoped>
</style>